<template>
  <base-section id="theme-features">
    <base-section-heading title="FirstVault Highlights" class="px-2">
      With FirstVault, you prevent your data from ever getting leaked, so you
      don't need to worry about data security any more.
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <base-avatar-card v-bind="feature" align="left" horizontal />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionThemeFeatures",

  data: () => ({
    features: [
      {
        title: "Strengthens your online security",
        icon: "mdi-fountain-pen-tip",
        text: "FirstVault encrypts all sensitive data stored in the vault to ensure complete security. Password managers are built in a way that nobody (not even FirstVault!) could access the user's vault contents, except for the user.",
      },
      {
        title: "Boosts your productivity",
        icon: "mdi-cellphone",
        text: "Use the FirstVault autofill and autosave features & save time online. Just log into an account once, and the app will prompt you to save your details in a click.",
      },
      {
        // color: 'primary',
        // dark: true,
        title: "Never Have To Remember Passwords Again",
        icon: "mdi-pencil-box-outline",
        text: "FirstVault auto generates strong random passwords for all your sites and apps. Organize your complex passwords and keep them securely in a single place",
      },
      {
        title: "Protect your data against Cyberattacks and Ransomware",
        icon: "mdi-security",
        text: "FirstVault prevents ransomware attacks by auto-generating strong and random passwords for each website and application that you use.",
      },
      {
        title: "Dark Web Monitoring",
        icon: "mdi-monitor-eye",
        text: "FirstVault monitors the dark web and alerts you to take remedial measures in case of a public data breach from one of the websites, applications or services used by you.",
      },
      {
        title: "Protects you on all your devices",
        icon: "mdi-cellphone-link",
        text: "Sync your vault across multiple devices and always have your passwords on hand. Use it on desktop, mobile, browser, or even when you're offline.",
      },
    ],
  }),
};
</script>
